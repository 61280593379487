<template>
  <div>
    <v-btn :loading="loading" v-if="!images || images.length < 1" @click="uploadImage()" color="info" dark>Add Image</v-btn>
    <v-menu v-else transition="scale-transition" offset-x>
      <template v-slot:activator="{ on }">
        <v-btn :loading="loading" v-on="on" color="info" dark @click="readImages">Choose Image</v-btn>
      </template>
      <v-list>
        <v-list-item @click="selectImage({id:null})">None</v-list-item>
        <v-list-item @click="uploadImage()">Add New Image</v-list-item>
        <v-list-item v-for="(image, index) in images" :key="index" @click="selectImage(image)">
          <cld-image :publicId="image.id" width="100" crop="scale" />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import Api from '@/services/Api.js'; 
export default {

  props: {
    boxOffice: {},
  },

  data () {
    return {
      images:[],
      loading: false,
    }
  },

  methods: {
    uploadImage() {
      var uploader = window.cloudinary.createUploadWidget({
        cloudName: "happytickets", 
        uploadPreset: "x6cvxr8u",
        folder: this.boxOffice.id,
        cropping: true
        }, (error, result) => { 
          if (error) {
            this.$toast.error(" ", error.message, {position: 'bottomCenter', theme: 'dark', backgroundColor : "red"});
          }
          if (result && result.event === "success") {
            this.loading = true
            this.selectFirstImage(); 
          }
        });
      uploader.open();
    },

    async selectFirstImage() { 
      await new Promise(r => setTimeout(r, 2000));
      await this.readImages();
      if (this.images && this.images.length) {
        this.selectImage(this.images[0]);
      }
      this.loading = false
    },

    async readImages() {
      this.loading = true
      this.images = await Api.post(this, "Image", "list", this.boxOffice);
      this.loading = false
    },

    selectImage(image) {
      this.$emit('selectImage', image);
    },
  },

  created() {
    this.readImages();
  },
}
</script>

